import React, { useEffect, useState } from "react";
import axios from "axios";

import moment from "moment";
import "moment/locale/th";

import Header from "./Header";
import Highcharts from "./Highcharts";
import Descript from "./Descript";
import Highcharts_realtime from "./Highcharts_realtime";
import Highcharts_realtime2 from "./Highcharts_realtime2";

import { Box, Grid, Backdrop, CircularProgress } from "@mui/material";

import Swal from "sweetalert2";

const AQHI1Hr = () => {
  // let min = 0;
  // let max = 600;
  // let random = Math.floor(Math.random() * (+max + 1 - +min)) + +min;
  // var xpm25 = Math.round(random);
  // var xpm25 = 40;

  let xURL = "";
  xURL = "https://www2.ntaqhi.info/api/";

  const geolocationAPI = navigator.geolocation;

  const [hname, sethname] = useState("");
  const [hdetailname, sethdetailname] = useState("");
  const [pm25, setpm25] = useState();

  const [dataChart, setdataChart] = useState([]);

  const [open, setOpen] = useState(false);

  const [hdevice_id, sethdevice_id] = useState("");

  const [dataRealtime, setdataRealtime] = useState([]);
  const [dateRealtime, setdateRealtime] = useState([]);

  // const [dataRealtime2, setdataRealtime2] = useState([]);
  // const [dataRealtime3, setdataRealtime3] = useState([]);

  // const [dataRealtime4, setdataRealtime4] = useState([]);
  // const [dataRealtime5, setdataRealtime5] = useState([]);

  useEffect(() => {
    getLocation();
  }, []);

  let timer = setInterval(() => {
    if (moment().format("mm:ss") == "01:00") {
      // getLocation();
      clearInterval(timer);
      window.location.reload();
      // console.log(555);
    }

    // if (moment().format("ss") == "00") {
    //   // clearInterval(timer);
    //   // window.location.reload();
    //   // getRealtime(hdevice_id);
    // }
  }, 2000);

  const getLocation = () => {
    setOpen(!open);

    var url = new URL(window.location.href);
    let xarea_point = url.searchParams.get("area-point");
    let xPages = url.searchParams.get("pages");

    let dateed = moment().utcOffset(7).format("YYYY-MM-DD HH:00:00");
    // let dateend = (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

    let dateed2 = moment()
      .utcOffset(7)
      .add(1, "hours")
      .format("YYYY-MM-DD HH:00:00");
    // let dateend2 =
    //   (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

    let datechklo = moment().utcOffset(7).format("YYYY-MM-DD HH:00");

    let dateoff1 = moment()
      .utcOffset(7)
      .add(5, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    let dateoffend1 =
      (Math.round(new Date(dateoff1).getTime() / 1000) - 600) * 1000;

    let dateoff2 = moment()
      .utcOffset(7)
      .add(10, "minutes")
      .format("YYYY-MM-DD HH:mm:ss");
    let dateoffend2 =
      (Math.round(new Date(dateoff2).getTime() / 1000) - 600) * 1000;
    //  console.log(dateoffend1,dateoffend2);

    if (!xarea_point) {
      geolocationAPI.getCurrentPosition(
        position => {
          const { coords } = position;

          // console.log(coords.latitude, coords.longitude);
          //  console.log(coords.latitude.toFixed(1), coords.longitude.toFixed(1));
          // 7.993125, 98.342522 ภูเก็ต
          //15.581628, 98.755481 ตาก

          axios
            .post(xURL + "getlocation2", {
              lat: coords.latitude,
              long: coords.longitude,
              datechklo: datechklo
            })
            .then(result => {
              // console.log(result.data[0]);

              if (result.data[0]) {
                window.location = "/?area-point=" + result.data[0].id;
              }

              // sethname(result.data[0].name);
              // sethdevice_id(result.data[0].device_id);

              // getRealtime(result.data[0].device_id);

              // axios
              //   .post(xURL + "getdatagaugeNew", {
              //     device_id: result.data[0].device_id,
              //     sdate: dateed,
              //     edate: dateed2
              //   })
              //   .then(result2 => {
              //     //  console.log(result2.data.rows[0].pm2_5);
              //     if (result2.data[0].pm25) {
              //       setpm25(result2.data[0].pm25);
              //     } else {
              //       setpm25(0);
              //     }
              //   })
              //   .catch(function(error) {
              //     // handle error
              //     console.log("problem here", error);
              //   });

              // getAQHI(result.data[0].device_id);

              // setOpen(false);
            })
            .catch(function(error) {
              // handle error
              console.log("problem here", error);
            });
        },
        error => {
          // console.log("error");
          // window.location = "/?area-point=50240363"; //ปิด defualt เครื่องวัดฝุ่นเนื่องจากปรับปรุงอาคาร 1
          window.location = "/?area-point=50240392";
        }
      );
    } else {
      axios
        .post(xURL + "getlocation", {
          area_point: xarea_point,
          pages: xPages
        })
        .then(result => {
          if (xarea_point) {
            if (!result.data[0]) {
              Swal.fire({
                icon: "error",
                title: "เกิดข้อผิดพลาด",
                text: "สถานที่นี้ offline หรือ ไม่มี area-point นี้",
                footer: "กรุณากรอก area-point ใหม่"
              });

              setOpen(false);
            } else {
              let xdetail = "";

              if (result.data[0].districts) {
                xdetail = "ต." + result.data[0].districts;
              } else {
                xdetail = "";
              }

              if (result.data[0].amphures) {
                xdetail = xdetail + " อ." + result.data[0].amphures;
              } else {
                xdetail = xdetail + "";
              }

              if (result.data[0].provinces) {
                xdetail = xdetail + " จ." + result.data[0].provinces;
              } else {
                xdetail = xdetail + "";
              }

              sethname(result.data[0].name);
              sethdetailname(xdetail);
              sethdevice_id(result.data[0].cmaqhi_device_id);

              getRealtime(result.data[0].device_id);

              // axios
              //   .post(xURL + "checkoffline", {
              //     device_id: result.data[0].device_id,
              //     sdate: dateoffend1,
              //     edate: dateoffend2
              //   })
              //   .then(result3 => {
              //     // console.log(result3.data.rowCount);
              //     if (result3.data.rowCount !== 0) {
              axios
                .post(xURL + "getdatagaugeNew", {
                  device_id: result.data[0].device_id,
                  sdate: dateed,
                  edate: dateed2
                })
                .then(result2 => {
                  // console.log(result2.data.rows[0].pm2_5);
                  if (result2.data[0].pm25) {
                    setpm25(result2.data[0].pm25);
                  } else {
                    setpm25(0);
                  }
                })
                .catch(function(error) {
                  // handle error
                  console.log("problem here", error);
                });

              getAQHI(result.data[0].device_id);
              // } else {
              //   setpm25("เครื่องออฟไลน์");
              // }

              setOpen(false);
              // })
              // .catch(function(error) {
              //   // handle error
              //   console.log("problem here", error);
              // });
            }
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const getAQHI = device_id => {
    if (device_id) {
      let dateed = moment()
        .utcOffset(7)
        .add(-12, "hours")
        .format("YYYY-MM-DD HH:00");
      // let dateend =
      //   (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

      let dateed2 = moment()
        .utcOffset(7)
        .add(1, "hours")
        .format("YYYY-MM-DD HH:00");
      // let dateend2 =
      //   (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

      // console.log(dateed, dateed2, dateend, dateend2);

      axios
        .post(xURL + "getchartgauge2", {
          device_id: device_id,
          sdate: dateed,
          edate: dateed2
        })
        .then(result => {
          if (result.data.length > 0) {
            const Tb1 = [];
            let barColors = "",
              barColors2 = "",
              xmaxaqhi = 0,
              xmaxpm25 = 0;

            result.data.forEach((i, idx) => {
              if (i.aqhi <= 50) {
                barColors = "#00e400";
              } else if (i.aqhi > 50 && i.aqhi <= 100) {
                barColors = "#ffff00";
              } else if (i.aqhi > 100 && i.aqhi <= 150) {
                barColors = "#ff7e00";
              } else if (i.aqhi > 150 && i.aqhi <= 200) {
                barColors = "#ff0000";
              } else if (i.aqhi > 200 && i.aqhi <= 300) {
                barColors = "#99004c";
              } else if (i.aqhi > 300) {
                barColors = "#4c0026";
              }

              if (i.pm25 >= 0 && i.pm25 <= 12) {
                barColors2 = "#00e400";
              } else if (i.pm25 > 12 && i.pm25 <= 35) {
                barColors2 = "#ffff00";
              } else if (i.pm25 > 35 && i.pm25 <= 55) {
                barColors2 = "#ff7e00";
              } else if (i.pm25 > 55 && i.pm25 <= 150) {
                barColors2 = "#ff0000";
              } else if (i.pm25 > 150 && i.pm25 <= 250) {
                barColors2 = "#99004c";
              } else if (i.pm25 > 205) {
                barColors2 = "#4c0026";
              }

              if (i.aqhi > xmaxaqhi) {
                xmaxaqhi = i.aqhi;
              }

              if (i.pm25 > xmaxpm25) {
                xmaxpm25 = i.pm25;
              }

              Tb1.push({
                dates: moment(i.d_date).format("HH:00"),
                aqhi: i.aqhi,
                fill: barColors,
                maxv: xmaxaqhi,
                pm25: i.pm25,
                fill2: barColors2,
                maxv2: xmaxpm25
              });
            });

            //  console.log(Tb1);
            setdataChart(Tb1);
          }
        })
        .catch(function(error) {
          // handle error
          console.log("problem here", error);
        });
    }
  };

  const getRealtime = device_id => {
    // console.log(device_id);

    let dateed = moment()
      .utcOffset(7)
      .add(-6, "hours")
      .add(10, "minutes")
      .format("YYYY-MM-DD HH:mm");
    let dateend = (Math.round(new Date(dateed).getTime() / 1000) - 600) * 1000;

    let dateed2 = moment()
      .utcOffset(7)
      .add(10, "minutes")
      .format("YYYY-MM-DD HH:mm");
    let dateend2 =
      (Math.round(new Date(dateed2).getTime() / 1000) - 600) * 1000;

    // console.log(dateed,dateed2);
    axios
      .post(xURL + "getrealtime", {
        device_id: device_id,
        sdate: dateend,
        edate: dateend2
      })
      .then(result => {
        // console.log(result.data.rows);
        if (result.data.rows.length > 0) {
          const Tb1 = [],
            Tb2 = [],
            Tb3 = [],
            Tb4 = [],
            Tb5 = [];

          let xtime = "",
            xcount = 0,
            xchk25 = 0,
            xchk1 = 0,
            xchk10 = 0;

          result.data.rows.forEach((i, idx) => {
            if (xtime !== "") {
              if (xtime !== moment(i.ts_m).utcOffset(7).format("LT")) {
                if (xcount == 1) {
                  if (xchk25 == 1 && xchk10 == 0 && xchk1 == 0) {
                    Tb2.push(0);
                    Tb3.push(0);
                  } else if (xchk10 == 1 && xchk25 == 0 && xchk1 == 0) {
                    Tb1.push(0);
                    Tb3.push(0);
                  } else if (xchk1 == 1 && xchk10 == 0 && xchk25 == 0) {
                    Tb1.push(0);
                    Tb2.push(0);
                  }
                }

                if (xcount == 2) {
                  if (xchk25 == 0) {
                    Tb1.push(0);
                  } else if (xchk1 == 0) {
                    Tb3.push(0);
                  }
                }

                xcount = 0;
                xchk25 = 0;
                xchk1 = 0;
                xchk10 = 0;
              }
            }

            if (i.key == "pm2_5") {
              Tb1.push(parseInt(i.valuepm));
              xcount = xcount + 1;
              xchk25 = 1;
            }

            if (i.key == "pm10") {
              Tb2.push(parseInt(i.valuepm));
              xcount = xcount + 1;
              xchk10 = 1;
              // Tb4.push(moment(i.ts_m).format("LT"));
            }

            if (i.key == "pm1") {
              Tb3.push(parseInt(i.valuepm));
              xcount = xcount + 1;
              xchk1 = 1;
            }

            if (i.key == "temperature") {
              Tb4.push(parseInt(i.valuepm2));
            }

            if (i.key == "humidity") {
              Tb5.push(parseInt(i.valuepm2));
            }

            xtime = moment(i.ts_m).utcOffset(7).format("LT");
          });
          // console.log(Tb1, Tb2, Tb3);
          // setdataRealtime(Tb1);
          // setdataRealtime2(Tb2);
          // setdataRealtime3(Tb3);

          // setdataRealtime4(Tb4);
          // setdataRealtime5(Tb5);

          setdateRealtime(Tb4);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });

    axios
      .post(xURL + "getrealtimedate", {
        device_id: device_id,
        sdate: dateend,
        edate: dateend2
      })
      .then(result => {
        if (result.data.rows.length > 0) {
          const Tb1 = [];

          result.data.rows.forEach((i, idx) => {
            Tb1.push(moment(i.ts_m).utcOffset(7).format("LT"));
          });
          // console.log(Tb1, Tb2, Tb3, Tb4);

          setdateRealtime(Tb1);
        }
      })
      .catch(function(error) {
        // handle error
        console.log("problem here", error);
      });
  };

  return (
    <React.Fragment>
      <Header
        name={hname}
        detail={hdetailname}
        deviceId={hdevice_id}
        pages={1}
      />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6.5}>
            <Highcharts pm25={pm25} pages={1} realtime={dateRealtime} />
          </Grid>
          <Grid item xs={12} sm={5.5}>
            <Descript pm25={pm25} dataChart={dataChart} pages={1} />
          </Grid>
        </Grid>
      </Box>

      {/* <Highcharts_realtime
        dataRealtime={dataRealtime}
        dateRealtime={dateRealtime}
        dataRealtime2={dataRealtime2}
        dataRealtime3={dataRealtime3}
        dataRealtime4={dataRealtime4}
        dataRealtime5={dataRealtime5}
      /> */}

      {/* <Highcharts_realtime2 dateRealtime={dateRealtime} dataRealtime={dataRealtime4}
        dataRealtime2={dataRealtime5}/> */}

      <Backdrop
        sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </React.Fragment>
  );
};

export default AQHI1Hr;
